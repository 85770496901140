<template>
  <div class="app-content content" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="row content-header">
        <div class="col-12 col-md-9 content-header-left mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="float-left pr-1 mb-0">{{ $route.meta.pageTitle }}</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body">
        <transition name="zoom-fade" mode="out-in">
          <div class="card">
            <div class="card-body">
              <div class="row mb-2">
                <div class="col-12">
                  Всего продано: {{ batches.total_qty }} шт,
                  на сумму <span class="amount positive">{{ batches.total_amount }}</span> грн
                  с себестоимостью <span class="amount negative">{{ batches.total_cost }}</span> грн
                  <br/>
                  Прибыль:
                  <span :class="['amount', batches.profit >= 0 ? 'positive' : 'negative']">
                  {{ batches.profit }}
                  </span> грн,
                  Рентабельность:
                  <span :class="['amount', batches.profit >= 0 ? 'positive' : 'negative']">
                  {{ batches.profitability }}%
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-3">
                  <label for="example-datepicker">Начало периода</label>
                  <b-form-datepicker
                      v-model="form.fromDate"
                      locale="ru-RU"
                      placeholder="Выберите дату"
                  />
                </div>
                <div class="col-12 col-md-3 mb-1 mb-md-0">
                  <label for="example-datepicker">Конец периода</label>
                  <b-form-datepicker
                      v-model="form.tillDate"
                      locale="ru-RU"
                      placeholder="Выберите дату"
                  />
                </div>
                <div class="col-12 col-md-3 align-self-end">
                  <button class="btn btn-primary" @click="getBatches">Сформировать</button>
                </div>
              </div>
            </div>
            <div class="position-relative table-responsive">
              <table class="table b-table">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Товар</th>
                  <th>Бренд</th>
                  <th>Кол-во</th>
                  <th>Себестоимость</th>
                  <th>Цена продажи</th>
                  <th>Рентабельность</th>
                  <th>Дата прихода</th>
                  <th>Категория</th>
                  <th>Дата</th>
                </tr>
                </thead>
                <tbody>
                  <tr v-for="batch in batches.batches" :key="batch.id">
                  <td class="font-weight-bold">#{{ batch.id }}</td>
                  <td>{{ batch.product_specification.product.name }}</td>
                  <td>{{ batch.product_specification.product.brand_name }}</td>

                  <td>{{ batch.qty }}</td>
                  <td>
                    <span class="amount negative">{{ batch.uah_cost }}</span>
                    <span class="currency">грн</span>
                  </td>
                  <td>
                    <span
                        :class="['amount', batch.uah_price >= batch.uah_cost ? 'positive' : 'negative']"
                    >
                      {{ batch.uah_price }}
                    </span>
                    <span class="currency"> грн</span>
                  </td>
                  <td>
                    <span
                        :class="['amount', batch.profitability >= 0 ? 'positive' : 'negative']"
                    >
                      {{ batch.profitability }}%
                    </span>
                  </td>
                  <td>{{ batch.batch.created_at | formattedDate }}</td>
                  <td>{{ batch.product_specification.product.category.path }} > {{ batch.product_specification.product.category.name }}</td>
                  <td>{{ batch.created_at | formattedDate }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormDatepicker } from 'bootstrap-vue';

export default {
  name: 'Turnover',
  components: {
    BFormDatepicker,
  },
  data() {
    return {
      form: {
        fromDate: null,
        tillDate: null,
      },
      batches: {
        batches: [],
        total_qty: 0,
      },
    };
  },
  async mounted() {
    await this.getBatches();
  },
  methods: {
    async getBatches() {
      this.batches = (await this.$api.batches.turnover({
        fromDate: this.form.fromDate,
        tillDate: this.form.tillDate,
      })).data;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.per-page-selector {
  width: 90px;
}

span.amount {
  font-size: 18px;
}
.positive {
  color: $success;
}
.negative {
  color: $danger;
}
span.currency {
  font-size: 10px;
}

.fixed-price {
  background-color: #f5f5f5;
}

.discount {
  text-transform: uppercase;
  &.warning {
    color: $warning;
    text-transform: uppercase;
  }
  &.danger {
    color: $danger;
  }
}
</style>
